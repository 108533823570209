import axios from 'axios';

const serverIP = 'https://auth.vendorobotics.com/v1';
// const serverSWAGGERIP = 'http://192.168.88.19:5000/v1'
// const serverSWAGGERIP = 'http://localhost:6012/v1';
const serverSWAGGERIP = 'https://vr-test.vendorobotics.com/v1';
// const serverSWAGGERIP = 'https://pb.vendorobotics.com/v1';
// const serverSWAGGERIP = 'http://ec2-18-219-228-246.us-east-2.compute.amazonaws.com:6012/v1';
const serverSWAGGERIPTESTOWY = 'https://vr-test.vendorobotics.com/v1';

const handleHeaderObject = () => {

    return {
        'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
        'accept': 'application/json',
        'Content-Type': 'application/json'
    }
}

const handleFormatDate = (valueDate) => {
    const year = valueDate.getFullYear();
    const month = String(valueDate.getMonth() + 1).padStart(2, '0');
    const day = String(valueDate.getDate()).padStart(2, '0');
    const hours = String(valueDate.getHours()).padStart(2, '0');
    const minutes = String(valueDate.getMinutes()).padStart(2, '0');
    const seconds = String(valueDate.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function serverGetDeviceItems() {
    return axios
        .get(serverIP + `/get`, {
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error
        });
}

export async function getDeviceLogs(serial, startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/logs?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceFails(serial, startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/fails?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceSale(serial, startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/sale-numbers?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceInfo(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/machines/details?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceCommands(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/remote-command/command-logs?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getItemGroups() {
    const response = await axios.get(`${serverSWAGGERIP}/Telemetry/item-group`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getUsers() {
    const response = await axios.get(`${serverSWAGGERIP}/Telemetry/users`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceLogsTEST(serial, startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/logs?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}



export async function getDeviceSaleTEST(serial, startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/sale-numbers?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}


export async function getDeviceLogsByRange({ serial, startRange, endRange }) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/logs?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceFailsByRange({ serial, startRange, endRange }) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/fails?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceSaleByRange({ serial, startRange, endRange }) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/machines/sale-numbers?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function postDeviceInfo(dataJson) {

    const { serial, textAreaValue, deviceNameValue, latitudeValue, longitudeValue, simCardValue, selectedCountryValue, selectedStateValue } = dataJson;

    let latValue, longValue;
    if (isNaN(latitudeValue) || latitudeValue === '') { latValue = null; }
    else { latValue = Number(latitudeValue) }
    if (isNaN(longitudeValue) || longitudeValue === '') { longValue = null; }
    else { longValue = Number(longitudeValue) }
    const response = await axios.post(`${serverSWAGGERIP}/machines/details?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {
            name: deviceNameValue,
            latitude: latValue,
            longitude: longValue,
            working_state: selectedStateValue,
            country: selectedCountryValue,
            sim_card_number: simCardValue,
            notes: textAreaValue
        },
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function postDeviceConfigCommandRadio(dataJson) {
    const { serial, commandName, commandValue } = dataJson;
    const response = await axios.post(`${serverSWAGGERIP}/remote-command/options?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {
            option_name: commandName,
            value: commandValue
        },
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function postDeviceConfigCommandMachineType(dataJson) {
    const { serial, commandName } = dataJson;
    const response = await axios.post(`${serverSWAGGERIP}/remote-command/ui-version?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {
            value: commandName,
        },
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function getDeviceConfigRadio(serial, commandName) {
    const response = await axios.get(`${serverSWAGGERIP}/remote-command/options?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}&option_name=${commandName}`,
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function getDeviceConfigUIVersion(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/remote-command/ui-version?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {
            headers: handleHeaderObject()
        })
    return response
}




export async function postDeviceConfigCommandColor(dataJson) {
    const { serial, realizationDate, commandName, commandValue } = dataJson;

    const response = await axios.post(`${serverSWAGGERIP}/remote-command/color-update?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {
            [commandName]: commandValue
        },
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function postDeviceConfigCommand(dataJson) {
    console.log(dataJson);
    const { serial, commandName, commandValue } = dataJson;
    const response = await axios.post(`${serverSWAGGERIP}/remote-command/options?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {
            option_name: commandName,
            value: commandValue
        },
        {
            headers: handleHeaderObject()
        })
    return response
}



export async function postDeviceConfigCommandReboot(dataJson) {
    const { serial } = dataJson;
    const response = await axios.post(`${serverSWAGGERIP}/remote-command/reboot?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {},
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function postDeviceConfigCommandResetCounter(dataJson) {
    const { serial } = dataJson;
    const response = await axios.post(`${serverSWAGGERIP}/machines/reset-return-counter`,
        { serial: serial },
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function postDeviceConfigCommandDispense(dataJson) {
    const { serial } = dataJson;
    const response = await axios.post(`${serverSWAGGERIP}/remote-command/dispense?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {},
        {
            headers: handleHeaderObject()
        })
    return response
}

export async function postDeviceConfigDelete(dataJson) {
    const { commandId } = dataJson;

    const response = await axios.put(`${serverSWAGGERIP}/Telemetry/remote-command/${commandId}`,
        '', {
        headers: handleHeaderObject()
    })
    return response
}

export async function postUserDelete(dataJson) {
    const { userId } = dataJson;

    const response = await axios.delete(`${serverSWAGGERIP}/Telemetry/users?user_id=${userId}`,
        {
            headers: handleHeaderObject()
        })
    return response
}


export async function postUserAdd(dataJson) {
    const { userCurrentInfoAdd, userGroupArr } = dataJson;
    const { currentAddName, currentAddSurname, currentAddPinCode, currentAddCardNumber, currentAddRadio } = userCurrentInfoAdd;
    const response = await axios.post(`${serverSWAGGERIP}/Telemetry/users`,
        {
            first_name: currentAddName,
            second_name: currentAddSurname,
            pin_code: parseInt(currentAddPinCode),
            card_number: currentAddCardNumber,
            access_level: parseInt(currentAddRadio),
            access_to_item_group: userGroupArr
        }, {
        headers: handleHeaderObject()
    })
    return response
}

export async function postUserEdit(dataJson) {
    const { userCurrentInfo, userGroupArr } = dataJson;
    const { currentId, currentName, currentSurname, currentPinCode, currentCardNumber, currentRadio } = userCurrentInfo;
    const response = await axios.post(`${serverSWAGGERIP}/Telemetry/users`,
        {
            user_id: currentId,
            first_name: currentName,
            second_name: currentSurname,
            pin_code: parseInt(currentPinCode),
            card_number: currentCardNumber,
            access_level: parseInt(currentRadio),
            access_to_item_group: userGroupArr
        }, {
        headers: handleHeaderObject()
    })
    return response
}


export async function postDeviceItemDelete(dataJson) {
    const { itemId } = dataJson;

    const response = await axios.put(`${serverSWAGGERIP}/Telemetry/XXX/${itemId}`,
        '', {
        headers: handleHeaderObject()
    })
    return response
}

export async function postItemGroupEdit(dataJson) {
    const { itemId, itemName } = dataJson;

    const response = await axios.put(`${serverSWAGGERIP}/Telemetry/item-group?item_group_id=${itemId}&new_name=${itemName}`,
        '', {
        headers: handleHeaderObject()
    })
    return response
}

export async function postItemGroupAdd(dataJson) {
    const { itemName } = dataJson;

    const response = await axios.post(`${serverSWAGGERIP}/Telemetry/item-group`,
        {
            "items_group_name": [
                itemName
            ]
        }, {
        headers: handleHeaderObject()
    })
    return response
}


export async function getAllDevices() {
    const response = await axios.get(`${serverSWAGGERIP}/machines/info?guid=${JSON.parse(sessionStorage.getItem("guid"))}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceConfigColors(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/machines/colors?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function postDeviceConfigColors(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/machines/colors?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}


export async function getDeviceStatus(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/machines/status?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceParameter(serial, nameParam) {

    const response = await axios.get(`${serverSWAGGERIP}/machines/params?guid=${JSON.parse(sessionStorage.getItem("guid"))}&param=${nameParam}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}


export async function getSupportedLanguages(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/languages/supported-languages?serial=${serial}`, {
        headers: handleHeaderObject()
    });
    return response;
}

export async function postActiveLanguages(dataJson) {
    const { serial, activeLanguages } = dataJson;
    const response = await axios.post(`${serverSWAGGERIP}/languages/active-languages?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`,
        {
            active_languages: activeLanguages
        },
        {
            headers: handleHeaderObject()
        });
    return response;
}

export async function getDeviceItemDetails(serial, itemid) {
    const response = await axios.get(`${serverSWAGGERIP}/Telemetry/item-history/${itemid}/in-device/${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}



export async function getDeviceHeartbeat(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/machines/heartbeat?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceLastWeekTransaction(serial) {
    const response = await axios.get(`${serverSWAGGERIP}/sale/last-week-sales?guid=${JSON.parse(sessionStorage.getItem("guid"))}&serial=${serial}`, {
        headers: handleHeaderObject()
    })
    return response
}




export async function GetDevices() {

    const response = await axios.get(`${serverSWAGGERIP}/machines/?guid=${JSON.parse(sessionStorage.getItem("guid"))}`, {
        headers: handleHeaderObject(sessionStorage.getItem('token'))
    })
    return response
}

export async function intervalGetDeviceDataAccess() {

    if (sessionStorage.getItem('token')) {
        const response = await axios.get(`${serverSWAGGERIP}/machines/?guid=${JSON.parse(sessionStorage.getItem("guid"))}`, {
            headers: handleHeaderObject(sessionStorage.getItem('token'))
        })
        return response
    }
}

// Check if the variable exists in local storage

export async function Authorize(data) {
    const { username, password } = data;

    const response = await axios.post(serverIP + `/user/login`, {
        username: username,
        password: password
    })
    return response
}

export async function getDeviceReturnMachine(startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/refund/contact-info?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}
export async function getDeviceReturnMachineTEST(startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/refund/contact-info?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceReturnMachineByRange({ startRange, endRange }) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/refund/contact-info?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceReturnPerson(startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/refund/recipe?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}
export async function getDeviceReturnPersonTEST(startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/refund/recipe?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceRatings(startRange, endRange) {

    const response = await axios.get(`${serverSWAGGERIP}/ratings/`, {
        // const response = await axios.get(`${serverSWAGGERIPTESTOWY}/ratings?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceRatingsTEST(startRange, endRange) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/refund/recipe?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getDeviceRatingsByRange({ startRange, endRange, serial }) {
    
    let response;
    if (startRange === null && endRange == null) {
        console.log('weszlo1')
        response = await axios.get(`${serverSWAGGERIP}/ratings/?serial=${serial.value}`, {
            headers: handleHeaderObject()
        })

    }
    else if (serial === null) {
        const convertedStartDate = handleFormatDate(startRange);
        const convertedEndDate = handleFormatDate(endRange);

        response = await axios.get(`${serverSWAGGERIP}/ratings/?end_date=${convertedEndDate}&start_date=${convertedStartDate}`, {
            headers: handleHeaderObject()
        })
    }
    else {
        const convertedStartDate = handleFormatDate(startRange);
        const convertedEndDate = handleFormatDate(endRange);

        response = await axios.get(`${serverSWAGGERIP}/ratings/?end_date=${convertedEndDate}&start_date=${convertedStartDate}&serial=${serial.value}`, {
            headers: handleHeaderObject()
        })

    }
    return response
}

export async function getDeviceReturnPersonByRange({ startRange, endRange }) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/refund/recipe?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getLastWeekLogs() {

    const response = await axios.get(`${serverSWAGGERIP}/logs/last-week-logs?guid=${JSON.parse(sessionStorage.getItem("guid"))}`, {
        headers: handleHeaderObject()
    })
    return response
}

export async function getInsights({ startRange, endRange }) {
    const convertedStartDate = handleFormatDate(startRange);
    const convertedEndDate = handleFormatDate(endRange);

    const response = await axios.get(`${serverSWAGGERIP}/sale/insight-sales?guid=${JSON.parse(sessionStorage.getItem("guid"))}&start_date=${convertedStartDate}&end_date=${convertedEndDate}`, {
        headers: handleHeaderObject()
    })

    return response
}